import React from 'react'
import Caption from './Caption';
import RateData from '../data/rate-data';
import { css } from '@emotion/react'

export default function RateCard(props) {
    const { ...rest } = props;

    const rateItemStyle = [
        css`

            + li {
                margin-top: 0.75rem;
                padding-top: 0.75rem;
                border-top: 1px solid var(--border-color);
            }
        `
    ]

    return (
        <div {...rest}>
            <ul className={`m-0 p-0 max-w-max `}>
                {RateData.map((rate, index) => {

                    return (


                        <li css={[rateItemStyle]} key={index}>
                            <div className={`flex flex-row items-center md:flex-nowrap flex-wrap`}>
                                {rate.value &&
                                    <div className={`text-center flex-trueauto px-5`}>
                                        <span className={`text-3xl leading-0 font-bold block mb-1 pt-1 text-primary-default font-secondary`}>{rate.value}</span>
                                        {rate.interval &&
                                        <Caption className={`md:max-w-[60%] md:mx-auto`}>{rate.interval}</Caption>
                                        }
                                    </div>
                                }
                                {rate.caption &&
                                    <div className={`text-left w-full flex-trueauto font-semibold font-primary px-5 border-l border-border min-h-[2.5rem] inline-flex items-center`}>
                                        {rate.caption}
                                    </div>
                                }
                            </div>
                        </li>
                    )
                })}
            </ul>
            
        </div>
    )
}