import React from 'react'
import Container from './Container'

const HeaderOffset = props => {

	const {children, ...rest} = props;

	if (!children) return null

	return (
		<Container size="small">
			<div className="bg-background pt-3 sm:pt-5 md:pt-10 -mt-20 md:-mt-32 lg:-mt-48 relative z-40 sm:px-5 md:px-10 lg:px-14 md:rounded-tr-sm md:rounded-tl-sm" {...rest}>

				{children}

			</div>

		</Container>
	)
}

export default HeaderOffset
