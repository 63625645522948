import React from 'react'
import Layout from '../components/layout'
// import Seo from "../components/seo"
import Seo2 from '../components/Seo2'
import { Link, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import PageHeader from '../components/PageHeader'
import Container from '../components/Container'
import CoverImage from '../components/CoverImage'
import HeaderOffset from '../components/HeaderOffset'
import Caption from '../components/Caption'
import Section from '../components/Section'
import List from '../components/List'
import RateCard from '../components/RateCard'
import Button from '../components/Button'

const ServicesPage = ( {data}) => {


	return (
		<Layout>
		{/* <Seo title="Services" /> */}
			<CoverImage crop={true}>
				<StaticImage

					src={`../images/stock/casak9-wvnStoYIB4.jpg`}
					alt="dogs playing"
					loading={`eager`}
					/>
			</CoverImage>
		<HeaderOffset>
			<PageHeader
				pageTitle="Services &amp; Rates"
				preheader="Covering all your dog boarding needs"
				description="We are a family-operated, home-based, dog boarding service. We offer a safe and healthy environment for your dogs while you are away."
				className="text-center"
				/>
		</HeaderOffset>
		<Container size={`medium`}>
		<div className="flex flex-wrap justify-start flex-row items-start -m-2 md:-m-3 lg:-m-4">
		{data.allServicesJson.edges
		.map(service => {

			// const serviceSlug = service.node.name.replace(/\s+/g, '-').toLowerCase()
			const serviceSlug = service.node.name.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');

			return (
				<div key={service.node.id} className={`p-2 md:p-3 lg:p-4 flex-fill w-full md:w-auto md:flex-1/2 md:max-w-1/2 lg:flex-1/2 lg:max-w-1/2 ${serviceSlug}-item`}>
					<div className="flex flex-row items-start flex-nowrap -mx-3">
						<div className="flex-trueauto px-3">
						<GatsbyImage alt={service.node.image.name} image={getImage(service.node.image.src)}
						className="rounded-full md:w-auto sm:w-28 w-20"
						/>
						</div>
						<div className="md:flex-8/12 md:max-w-8/12 px-3 md:pr-4 md:self-center">
							<h2 className="mb-2 md:text-2xl lg:text-3xl"><Link to={`/service/${serviceSlug}`}>{service.node.name}</Link></h2>
							<Caption>{service.node.caption}</Caption>
						</div>
					</div>
				</div>
			)
		})}
		</div>

		</Container>
		<Section size="medium">
      <Section.Col>
        <Section.Content
        header="Your dog's home away from home."
        preheader={null}
        content={`
          <p>CasaCanine is your dog’s home away from home. You can relax and enjoy your time away, knowing that your furry friend is happy, healthy and loved.</p>
          <p>
		  We have two huge completely-fenced yards for supervised play-time.
          </p>
          `}
        >
			<List>
				<List.ListItem>
					Dog’s pens are secure and private.
				</List.ListItem>
				<List.ListItem>
					Bowls and bedding are provided.
				</List.ListItem>
				<List.ListItem>
					No extra charges for play-time or medication (if needed).
				</List.ListItem>
				<List.ListItem>
				Kennel is climate controlled with heating and air conditioning
				</List.ListItem>
			</List>
        </Section.Content>

      </Section.Col>
      <Section.Col hasMedia={true}>
        <StaticImage

        src={`../images/business/casak9-4297.jpg`}
        alt="Casa Canine HQ"
        />
		<Caption className={`mt-2`}>Casa Canine HQ</Caption>
      </Section.Col>
		
    </Section>

		<Section size={`medium`} id={`our-rates`}>
			<Section.Col className={`md:!pl-5 md:flex-[45%] md:max-w-[45%]`}>
				<Section.Content 
				header="Our Rates"
				content={`
					<p>
					Includes several supervised group-play sessions daily, belly rubs, feeding, early-morning and late-night toilet breaks, and medication (if needed.)
					</p>
					<p>
					Reduced rates for long-term stays and multiple dogs.
					</p>
				`}
				>

				{/* <ul className={`grid grid-flow-col auto-cols-auto divide-x divide-solid divide-border`}>
					<li className={`px-3 lg:px-4 text-center`}>
						<strong className={`text-xl font-extrabold text-secondary-default`}>2</strong>
						<Caption>Fenced Yards</Caption>
					</li>
					<li className={`px-3 lg:px-4 text-center`}>
						<strong className={`text-xl font-extrabold text-secondary-default`}>10,000 ft&sup2;</strong>
						<Caption>Enclosed Yard Space</Caption>
					</li>
					<li className={`px-3 lg:px-4 text-center`}>
						<strong className={`text-xl font-extrabold text-secondary-default`}>60</strong>
						<Caption>Acres of Fields and Forests for Walks</Caption>
					</li>
					<li className={`px-3 lg:px-4 text-center`}>
						<strong className={`text-xl font-extrabold text-secondary-default`}>18</strong>
						<Caption>Climate-Controlled Pens</Caption>
					</li>
				</ul> */}

				</Section.Content>
			</Section.Col>
			<Section.Col className={`md:-order-1 md:flex-[55%] md:max-w-[55%]`}>
				<Section.Content>
					<RateCard className={`md:bg-accent-lighter md:p-5 md:pt-7 md:rounded-sm`} />
				</Section.Content>
			</Section.Col>
		</Section>

			<Section size={`medium`}>
				<Section.Col>
					<Section.Content
						header={`Group-play is just part of the routine here.`}
						preheader={`What's included`}
						content={`
						<p>
						Dogs are incredibly social animals and need to be together to have their social needs met. That’s why play is such a big part of every day here. Providing a safe space for dogs to be their best selves is a big part of what we offer.
						</p>
						<p>
						We post pictures often to our <a href="https://www.instagram.com/casacanine613/?hl=en" target="_blank" rel="noopener noreferrer" title="Casa Canine Instagram">Instagram</a> and <a href="https://www.facebook.com/CasaCanine" target="_blank" rel="noopener noreferrer" title="Casa Canine Facebook">Facebook</a> pages so you can see your best friend while you’re away.
						</p>
						`}
					/>
				</Section.Col>
				<Section.Col hasMedia={true}>
					<StaticImage 
						src={`../images/business/casak9-562DE7.jpg`}
						aspectRatio={1}
						alt={`Dogs at Casa Canine`}
					/>
				</Section.Col>
			</Section>
			<Section size={`medium`}>
			<Section.Col hasMedia={true}>
					<StaticImage 
						src={`../images/business/casak9-02893.jpg`}
						aspectRatio={1}
						alt={`Dogs at Casa Canine`}
					/>
				</Section.Col>
				<Section.Col>
					<Section.Content
						header={`You can always see what your dog has been up to while you’re away.`}
						content={`
						<p>
						We are certified by the City of Ottawa and have been inspected by the Humane Society. We have Pet First Aid certification from St. John Ambulance.
						</p>
						<p>
						We accept payment by cash, cheque or email money transfer.
						</p>
						<p>
						Check out our Facebook Page to see pictures of your dog’s latest adventures with his friends at CasaCanine.
						</p>
						`}
					>
						<Button type={`external`} href={`http://facebook.com/casacanine`}>Facebook Page</Button>
					</Section.Content>
				</Section.Col>
				
			</Section>
		</Layout>

	)
}

export default ServicesPage

export const Head = () => <Seo2 title={`Services`} />

export const query = graphql`
  query {
	  allServicesJson {
      edges {
        node {
          name
		  id
		  caption
		  image {
            src {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1)
              }
            }
			name
          }
        }
      }
    }
  }
`
