import React from 'react'
import PropTypes from 'prop-types'

const Caption = ( {color, children, ...rest} ) => {

	const captionColor = {
		color: color,
		opacity: `0.65`
	}

	return (
		<div {...rest}>
			<p
			className="m-0 text-xs leading-tight font-medium tracking-wide" style={captionColor}>
			{children}
			</p>
		</div>
	)
}

Caption.propTypes = {
	children: PropTypes.node.isRequired,
	color: PropTypes.string,
}

Caption.defaultProps = {
	color: `var(--text-color)`,
}

export default Caption
