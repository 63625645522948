import React from 'react'
import PropTypes from 'prop-types'
import Container from './Container'
import * as sectionStyles from '../styles/section.module.scss'


const Content = ( {as: Component, header, preheader,subheader, content, bigHeader, children, ...rest} ) => {

	return (

		<div {...rest}>
		{preheader &&
			<h6
			dangerouslySetInnerHTML={{__html: preheader}}
			className="mb-2 block text-primary-default"
			/>
		}
			{header &&
				<Component
				dangerouslySetInnerHTML={{__html: header}}
				className={`${bigHeader === true ? 'text-5xl lg:text-6xl' : 'lg:text-5xl text-[2.35rem]'} font-semibold text-text mb-3`}
				/>
			}
			{subheader &&
				<h3
				dangerouslySetInnerHTML={{__html: subheader}}
				className={`h3 text-primary-default`}
				/>
			}
			{content &&
				<div
				dangerouslySetInnerHTML={{__html: content}}

				/>
			}
			{children &&
				children
			}
		</div>
	)
}

Content.propTypes = {
	header: PropTypes.string,
	preheader: PropTypes.string,
	subheader: PropTypes.string,
	content: PropTypes.string,
	children: PropTypes.node,
	bigHeader: PropTypes.bool
}

Content.defaultProps = {
	header: ``,
	preheader: ``,
	subheader: ``,
	content: ``,
	bigHeader: false,
	as: 'h2'
}

const Col = ({children, className, gaps, addFlair, hasMedia}) => {
	return (
		<div className={`${gaps} w-full ${className} ${sectionStyles.sectionColumn} ${hasMedia ? 'md:order-none order-first' : 'md:order-none order-last'}`}>
			{children}

			{addFlair && <div className={sectionStyles.flair} />}
		</div>
	)
}

Col.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	gaps: PropTypes.string,
	addFlair: PropTypes.bool
}

Col.defaultProps = {
	className: `md:flex-1/2 md:max-w-1/2`,
	gaps: `md:px-5 lg:px-8 xl:px-10 2xl:px-16`,
	addFlair: false
}

export class Section extends React.Component {
	static Col = Col;
	static Content = Content;

	render() {

		const {size, className, gapOffset, vAlign, children, ...rest} = this.props;

		return (
			<div className={`w-full ${sectionStyles.sectionBlock}`} {...rest}>
				<Container size={size}>

				<div className={`flex flex-row justify-center md:flex-nowrap flex-wrap ${gapOffset} ${className} ${vAlign}`}>
					{children}
				</div>

				</Container>

			</div>
		)
	}
}

Section.propTypes = {
	children: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([Col, Content])
    })
	),
	size: PropTypes.string,
	className: PropTypes.string,
	gapOffset: PropTypes.string,
	vAlign: PropTypes.string,
}

Section.defaultProps = {
	size: ``,
	className: ``,
	gapOffset: `md:-mx-5 lg:-mx-8 xl:-mx-10 2xl:-mx-16`,
	vAlign: `items-center`,
}


export default Section
