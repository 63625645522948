const RateData = [
    {
        value: `$25`,
        interval: `per day`,
        caption: `Daycare`,
        content: null
    },
    {
        value: `$35`,
        interval: `per day`,
        caption: `Kennel boarding`,
        content: null
    },
    // {
    //     value: `$40`,
    //     interval: `per day`,
    //     caption: `In-home boarding`,
    //     content: null
    // },
    {
        value: `$250`,
        interval: `11 daycare stays for the price of 10`,
        caption: `Daycare discount card`,
        content: null
    },
    {
        value: `$60`,
        interval: `per day`,
        caption: `Kennel boarding for 2 dogs`,
        content: null
    },
    // {
    //     value: `$70`,
    //     interval: `per day`,
    //     caption: `In-home boarding for 2 dogs`,
    //     content: null
    // }
]

export default RateData